import React,{useState} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
const Updateform = () => {
       
      const [image, setImage] = useState('');
      const [name, setName] = useState('');
      const [quantity, setQuantity] = useState('');
      const [price, setPrice] = useState('');
      const navigate = useNavigate();
      const {id} = useParams();
   
   function handleUpdate(event){
    event.preventDefault();
    axios.put(`http://screen.abtecz.com/api/products/${id}`,{name,image,quantity,price}) // https://xyz.com/crud.php
    .then((response) => {
        console.log(response);
        navigate('/admin');
   }).catch(err=>console.log(err));
}
   
  return (
  
<div className='d-flex vh-100 bg-primary justify-content-center align-items-center '>
<div className='w-80 bg-white rounded p-3'>
    <form onSubmit={handleUpdate}>
         <h2>Update Product</h2>
         <div className='mb-2'>
            <label className='form-label'>Name:</label>
            <input type='text' placeholder='Enter Name of product' className='form-control'
                        onChange={(e) => setName(e.target.value)}/>
         </div>
         <div className='mb-2'>
            <label className='form-label'>Image:</label>
            <select  className='form-select'
                        onChange={(e) => setImage(e.target.value)}>

            <option >Select </option>
            <option value="./assets/Bisleri.png">Bisleri </option>
            <option value="./assets/RedBull.png">RedBull </option>
            <option value="./assets/Gatorade.png">Gatorade </option>
            <option value="./assets/Coke.png">Coke </option>
             <option value="./assets/Cigarette.png">Cigarette</option>
             <option value="./assets/Buckedup.png">Bucked up </option>
            <option value="./assets/sandwich.png">Sandwich </option>
            <option value="./assets/sprite.png">Sprite </option>
            <option value="./assets/pepsi.png">Pepsi </option>
            </select>
         </div>
         <div className='mb-2'>
            <label className='form-label'>Quantity:</label>
            <input type='text' placeholder='Enter Quantity' className='form-control'
                      onChange={(e) => setQuantity(e.target.value)}/>
         </div>
         <div className='mb-2'>
            <label className='form-label'>Price:</label>
            <input type='text' placeholder='Enter Price offered' className='form-control'
                        onChange={(e) => setPrice(e.target.value)}/>
         </div>
            <button className='btn btn-success'>Update</button>
    </form>

</div>
</div>


  )
}

export default Updateform