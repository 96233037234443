import React from 'react'
import { useState,useEffect } from 'react';
import axios from 'axios';
import { useMediaQuery } from 'react-responsive';
const HomePage = () => {
    const [products, setProducts] = useState([  
        { id: "", name: '', image: '', quantity: "", price: "" },
        { id: "", name: '', image: '', quantity: "", price: "" },
        { id: "", name: '', image: '', quantity: "", price: "" },
        { id: "", name: '', image: '', quantity: "", price: "" },
        { id: "", name: '', image: '', quantity: "", price: "" },
        { id: "", name: '', image: '', quantity: "", price: "" },
        { id: "", name: '', image: '', quantity: "", price: "" },]);
    
        const isPortrait = useMediaQuery({ orientation: 'portrait' });

    useEffect(() => {
        axios.get('http://screen.abtecz.com/api/products')  // https://xyz.com/crud.php
         .then(res=>setProducts(res.data)) 
         .catch(err=>console.log(err));
      }, [products]);
  
  return (
    <div className='main-container' style={{display:"flex",backgroundImage:isPortrait?"":'url("./assets/footer2.png")',
        backgroundSize: 'cover',  backgroundPosition: 'center center',  backgroundRepeat: 'no-repeat',
        width:"100vw",height:"100vh",flexDirection:isPortrait?"column":"row"}}>
{/* Deal of the day */}
<div className='left-half' style={{display:"flex",flexDirection:isPortrait?"row":"column",justifyContent:"center",
    alignItems:"center",backgroundColor:"#edf4f3",width:isPortrait?"100vw":"35vw",height:isPortrait?"35vh":"100vh",zIndex:"-1"}}>
    <img src={products[0].image} alt={products[0].name} style={{height:isPortrait?"32vh":"60vh"}}/>
    <div style={{textAlign:"center"}}>
    <h1 style={{color:"#f16f37",fontFamily:"cursive"}}>Deal of the Day</h1>
    <h1 style={{borderBottom:"2px dotted #f16f37"}}>{products[0].name} </h1>
    <h1 style={{color:"#f16f37"}}>{products[0].quantity}/${products[0].price} only</h1>
    </div>
</div>

{ /* This is the 2nd section */ }
<div className='right-half'style={{display:"flex",flexDirection:"column",
    width:isPortrait?"100vw":"65vw",height:isPortrait?"65vh":"100vh"}}>
        <div className='cards' style={{display:"flex",flexDirection:"row",zIndex:"-1",
            alignItems:"center",justifyContent:"space-evenly",backgroundColor:"white",height:isPortrait?"35vh":"70vh"}}>
       <div style={{display:"flex",flexDirection:"column",alignItems:"center",borderRadius:"12%",
        background: 'linear-gradient(to bottom, transparent 30%, #edf4f3 70%)'}}>
        <img src={products[1].image} alt={products[1].name} style={{height:isPortrait?"22vh":"40vh"}}/>
       {
        isPortrait?<><p style={{lineHeight:0,fontWeight:"bold"}}>{products[1].name}</p>
        <p style={{color:"#f16f37",fontFamily:"cursive",lineHeight:1}}>Offer Price</p>
        <p style={{color:"#379206",lineHeight:0.5,fontWeight:"bold"}}>{products[1].quantity}/${products[1].price} </p></>:
      <>
        <h3 style={{lineHeight:1}}>{products[1].name}</h3>
        <h4 style={{color:"#f16f37",fontFamily:"cursive",lineHeight:1}}>Offer Price</h4>
        <h2 style={{color:"#379206",lineHeight:1}}>{products[1].quantity}/${products[1].price} </h2></>
    }
        </div>
        <div style={{display:"flex",flexDirection:"column",alignItems:"center",borderRadius:"12%",
        background: 'linear-gradient(to bottom, transparent 30%, #edf4f3 70%)'}}>
        <img src={products[2].image} alt={products[2].name} style={{height:isPortrait?"22vh":"40vh"}}/>
       {
        isPortrait?<><p style={{lineHeight:0,fontWeight:"bold"}}>{products[2].name}</p>
        <p style={{color:"#f16f37",fontFamily:"cursive",lineHeight:1}}>Offer Price</p>
        <p style={{color:"#379206",lineHeight:0.5,fontWeight:"bold"}}>{products[2].quantity}/${products[2].price} </p></>:
      <>
        <h3 style={{lineHeight:1}}>{products[2].name}</h3>
        <h4 style={{color:"#f16f37",fontFamily:"cursive",lineHeight:1}}>Offer Price</h4>
        <h2 style={{color:"#379206",lineHeight:1}}>{products[2].quantity}/${products[2].price} </h2></>
    }
        </div>
        <div style={{display:"flex",flexDirection:"column",alignItems:"center",borderRadius:"12%",
        background: 'linear-gradient(to bottom, transparent 30%, #edf4f3 70%)'}}>
        <img src={products[3].image} alt={products[3].name} style={{height:isPortrait?"22vh":"40vh"}}/>
       {
        isPortrait?<><p style={{lineHeight:0,fontWeight:"bold"}}>{products[3].name}</p>
        <p style={{color:"#f16f37",fontFamily:"cursive",lineHeight:1}}>Offer Price</p>
        <p style={{color:"#379206",lineHeight:0.5,fontWeight:"bold"}}>{products[3].quantity}/${products[3].price} </p></>:
      <>
        <h3 style={{lineHeight:1}}>{products[3].name}</h3>
        <h4 style={{color:"#f16f37",fontFamily:"cursive",lineHeight:1}}>Offer Price</h4>
        <h2 style={{color:"#379206",lineHeight:1}}>{products[3].quantity}/${products[3].price} </h2></>
    }
        </div>
       
</div>
                
        {/* This is the 3rd section */}
        <div className='foot' style={{display:"flex", alignItems:"center",justifyContent:"space-evenly",
     height:"30vh",width:isPortrait?"100vw":"65vw",backgroundColor:isPortrait?"rgb(246 225 176)":"",
     borderRadius:isPortrait?"10%":""}}>
        <div style={{display:"flex"}}>
            <div><img src={products[4].image} alt={products[4].name} style={{height:isPortrait?"17vh":"20vh"}}/></div>
       {isPortrait?<> <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}> 
       <h5 style={{borderBottom:"2px dotted #000",textAlign:"center"}}>{products[4].name}</h5>
         <h6 style={{color:"#379206"}}>{products[4].quantity}/${products[4].price} </h6></div>
       </>:<> <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
         <h3 style={{borderBottom:"2px dotted #000",textAlign:"center"}}>{products[4].name}</h3>
         <h2 style={{color:"#379206"}}>{products[4].quantity}/${products[4].price} </h2></div>
       </>}
        </div>
         
       
       <div style={{display:"flex"}}>
            <div><img src={products[5].image} alt={products[5].name} style={{height:isPortrait?"17vh":"20vh"}}/></div>
            {isPortrait?<> <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}> 
              <h5 style={{borderBottom:"2px dotted #000",textAlign:"center"}}>{products[5].name}</h5>
         <h6 style={{color:"#379206"}}>{products[5].quantity}/${products[5].price} </h6></div>
       </>:<> <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}> 
        <h3 style={{borderBottom:"2px dotted #000",textAlign:"center"}}>{products[5].name}</h3>
         <h2 style={{color:"#379206"}}>{products[5].quantity}/${products[5].price} </h2></div>
       </>}
       
        </div>
        <div style={{display:"flex"}}>
            <div><img src={products[6].image} alt={products[6].name} style={{height:isPortrait?"17vh":"20vh"}}/></div>
            {isPortrait?<> <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}> 
              <h5 style={{borderBottom:"2px dotted #000",textAlign:"center"}}>{products[6].name}</h5>
         <h6 style={{color:"#379206"}}>{products[6].quantity}/${products[6].price} </h6></div>
       </>:<> <div style={{display:"flex",flexDirection:"column",justifyContent:"center"}}>
         <h3 style={{borderBottom:"2px dotted #000",textAlign:"center"}}>{products[6].name}</h3>
         <h2 style={{color:"#379206"}}>{products[6].quantity}/${products[6].price} </h2></div>
       </>}
       
        </div>
       
        </div>
        
        </div>
    </div>
    
  )
}

export default HomePage