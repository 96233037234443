
import axios from 'axios';

import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';

const AdminPage = () => {
const [datas,setDatas] =useState([]);
useEffect(() => {
    axios.get('http://screen.abtecz.com/api/products') // https://xyz.com/crud.php
     .then(res=>setDatas(res.data)) 
     .catch(err=>console.log(err));
  }, []);
  
  return (
    
    <div className='d-flex  justify-Content-center align-items-center '>
<div className='w-100 bg-white rounded p-3'>
    
<table className='table'>
<thead>
<tr>
    <th>SNo</th>
    <th>Name </th>
    <th>Image</th>
    <th>Quantity</th>
    <th>Price</th>
    <th>Action</th>
</tr>
</thead>
<tbody>
{
    datas.map((data, index) => (
        <tr key={index}>
            <td>{data.id}</td>
            <td>{data.name}</td>
            <td>
                <img src={data.image} alt={data.name} style={{height:"10vh"}}/>
            </td>
            <td>{data.quantity}</td>
            <td> {data.price}</td>
            <td>
                <Link to={`/update/${data.id}`} className='btn btn-primary' >Update</Link>
            </td>
        </tr>
        
        ))
}
</tbody>
</table>
</div>
    </div>
    
  );
};

export default AdminPage;

